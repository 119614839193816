import { idForEntity } from 'api/schemas'
import { parseISO } from 'lib/date-fns'
import {
  get,
  pick,
  includes,
  isEmpty,
  isEqual,
  keyBy,
  map,
  mapValues,
  omit,
  omitBy,
  pickBy,
  union,
} from 'lodash'
import * as types from './actionTypes'
import networkStatusEnum from './networkStatusEnum'
import { getHasData, getPageInfo, keyForQuery, getPaginationOrPage } from './selectors'

// TODO Docs
const initialSetState = {
  // Query used to fetch this set
  // (typically a search query)
  query: {},

  // pagination.pageSize
  // pagination.totalPages
  // pagination.totalCount
  // pagination.pages[] Map of page number to { pageInfo }
  // pagination.pages[].ids Entity IDs on the given page
  // pagination.pages[].pageInfo Meta for the given page
  // pagination.pages[].shouldClearAllPages
  // pagination.pages[].pageInfo.nextPageUrl
  // pagination.pages[].pageInfo.lastStatus
  // pagination.pages[].pageInfo.lastUpdated
  // pagination.pages[].pageInfo.maxAge
  // pagination.pages[].pageInfo.networkStatus
  pagination: {
    pageSize: null,
    totalPages: null,
    totalCount: null,
    pages: {},
  },
}

/**
 * Merges the current state's entity sets with a new result set from the API.
 */
export const mergeEntitySets = options => {
  const {
    state,
    setKey,
    page = 1,
    pageSize = null,
    totalPages = null,
    totalCount = null,
    pageInfo = {},
    shouldClearAllPages,
    shouldUnionResults,
    query,
    allEntitySets,
  } = options
  const currentSet = allEntitySets?.[setKey] || state.entitySets[setKey] || initialSetState
  const oldIds = get(currentSet, ['pagination', 'pages', page, 'ids'])
  const payloadIds = pageInfo.ids

  // `ids` must remain null instead of an empty array if we don't have canonical data
  // from the backend yet (first time after ENTITIES_FETCH_BEGIN
  // is processed). An empty array means that there are no results for this
  // query on the backend
  const newIds = shouldUnionResults && oldIds ? union(payloadIds, oldIds) : payloadIds

  const entitySets = allEntitySets || state.entitySets
  return {
    ...entitySets,
    [setKey]: {
      ...currentSet,
      query: omit(query, 'page') || currentSet.query,
      pagination: {
        // Note: pagination ONLY contains 'totalPages' and 'pages'.
        totalPages: totalPages || currentSet.pagination.totalPages,
        pageSize: pageSize || currentSet.pagination.pageSize,
        totalCount: typeof totalCount === 'number' ? totalCount : currentSet.pagination.totalCount,
        pages: {
          ...(shouldClearAllPages ? {} : currentSet.pagination.pages),
          [page]: {
            ...currentSet.pagination.pages[page],
            ...pageInfo,
            ...(newIds ? { ids: newIds } : {}),
          },
        },
      },
    },
  }
}

/**
 * Injects type information into entities
 *
 * @param {[Object]} entities
 * @param {String} entityType
 */
export const addTypeToEntities = (entities, entityType) =>
  map(entities, entity => ({ ...entity, $type: entityType }))

/**
 * Partial updates make refreshing more complex in some cases.
 * If an entity accumulates data from multiple API endpoints, updating
 * the entity from one endpoint but not the other can mean the data
 * can be partially stale.
 *
 * Entities with the `_deleted` flag will be removed from the entity map.
 *
 * @param {*} entityMap
 * @param {*} payload
 */
export const updateEntityMap = (entityMap, payload) => {
  const entitiesFromPayload = keyBy(payload, idForEntity)

  const updated = mapValues(entityMap, (entity, id) => ({
    ...entity,
    ...entitiesFromPayload[id],
  }))

  const existingIds = Object.keys(updated)
  const added = pickBy(entitiesFromPayload, (value, id) => !includes(existingIds, id))

  const newEntityMap = omitBy(
    {
      ...updated,
      ...added,
    },
    entity => entity._deleted,
  ) // eslint-disable-line

  return newEntityMap
}

// See entitySet.query
const areQueriesEqual = (firstQuery, secondQuery) => isEqual(firstQuery || {}, secondQuery || {})

// @private
const determineNetworkStatus = ({ hasData, didChangeQuery, didRequestExtraPage }) => {
  if (!hasData && !didRequestExtraPage) {
    return networkStatusEnum.loading
  } else if (!hasData && didRequestExtraPage) {
    return networkStatusEnum.fetchMore
  } else if (hasData && didChangeQuery) {
    return networkStatusEnum.setVariables
  } else if (hasData && !didChangeQuery) {
    return networkStatusEnum.refetch
  } else {
    return networkStatusEnum.ready
  }
}

// @private
const entitiesFetchInfo = (state, action) => {
  // ENTITIES_FETCH_* actions should contain pagination info to let
  // us know which page they're updating.
  const beginAction = get(action, 'meta.beginAction', action)
  const didRequestNextPage = get(action, 'meta.page') === 'next'

  const { shouldCacheAllQueries } = beginAction.meta || {}

  const { totalPages, totalCount, pageSize, updatedAt } = action.meta || {}

  const query = beginAction.payload
  const setKey = keyForQuery(shouldCacheAllQueries ? query : {})

  const pages = getPageInfo(state, { setKey }).pages
  const page = didRequestNextPage ? Object.keys(pages).length + 1 : get(action, 'meta.page') || 1
  const didRequestExtraPage = !isEmpty(pages) && !includes(Object.keys(pages), String(page))
  const hasData = getHasData(state, { page, setKey })
  const pageInfo = getPageInfo(state, { page, setKey })
  const lastUpdated = updatedAt ? parseISO(updatedAt) : new Date()
  const isRefetch = pageInfo.networkStatus === networkStatusEnum.refetch

  return {
    setKey,
    page,
    totalPages,
    totalCount,
    pageSize,
    query,
    hasData,
    pageInfo,
    isRefetch,
    didRequestExtraPage,
    lastUpdated,
  }
}

/**
 * Reducer for different stages fo fetching: BEGIN, SUCCEEDED, FAILED
 */
export const entityListReducers = {
  [types.ENTITIES_FETCH_BEGIN]: (state, action) => {
    const info = entitiesFetchInfo(state, action)
    const { setKey, query, hasData, didRequestExtraPage } = info
    const previousQuery = get(state.entitySets, [setKey, 'query'])
    const didChangeQuery = !areQueriesEqual(query, previousQuery)
    const networkStatus = determineNetworkStatus({ didChangeQuery, hasData, didRequestExtraPage })

    return {
      ...state,
      lastError: null,
      lastStatus: null,
      entitySets: mergeEntitySets({
        state,
        ...info,
        pageInfo: {
          // On networkStatusEnum.setVariables, previous pageInfo.ids should be preserved
          // and cleared only after request success / failure
          networkStatus,
        },
        // Clear only after success / failure
        shouldClearAllPages: false,
        // Begin actions MUST remain non-destructive (any existing cache is preserved)
        shouldUnionResults: true,
      }),
    }
  },
  [types.ENTITIES_CREATE_SUCCEEDED]: (state, action, { entityType }) => {
    const newEntities = addTypeToEntities(action.payload, entityType)
    const querySetKeys = get(action, 'meta.requestOptions.meta.querySetKeys')
    const setKeys = get(action, 'meta.requestOptions.entity', []).map(entity =>
      keyForQuery(querySetKeys ? pick(entity, querySetKeys) : {}),
    )
    let entitySets = state.entitySets
    setKeys.forEach((setKey, index) => {
      const page = 1
      const previousPageInfo = getPageInfo(state, { page, setKey })
      if (!isEmpty(previousPageInfo)) {
        const pageInfo = { ...previousPageInfo, ids: [action.payload[index].id] }
        const totalCount =
          getPaginationOrPage(state, { setKey }).totalCount +
          (action.type.includes('CREATE_SUCCEEDED') ? 1 : 0)
        entitySets = mergeEntitySets({
          allEntitySets: entitySets,
          setKey,
          page,
          pageInfo,
          shouldUnionResults: true,
          totalCount,
        })
      }
    })
    return {
      ...state,
      entityMap: updateEntityMap(state.entityMap, newEntities),
      entitySets,
      isSavingData: false,
    }
  },
  [types.ENTITIES_FETCH_SUCCEEDED]: (state, action, { entityType, maxAge }) => {
    const info = entitiesFetchInfo(state, action)
    const { lastUpdated, isRefetch, pageInfo } = info
    const didChangeQuery = pageInfo.networkStatus === networkStatusEnum.setVariables
    const newEntities = addTypeToEntities(action.payload, entityType)

    return {
      ...state,
      entityMap: updateEntityMap(state.entityMap, newEntities),
      lastError: null,
      lastStatus: null,
      entitySets: mergeEntitySets({
        state,
        ...info,
        pageInfo: {
          ids: map(newEntities, idForEntity),
          // Here we assume that for the same pageInfo (query) we never run
          // two concurrent requests
          networkStatus: networkStatusEnum.ready,
          nextPageUrl: get(action.meta, 'nextPageUrl'),
          lastUpdated,
          maxAge,
        },
        shouldClearAllPages: isRefetch || didChangeQuery,
        shouldUnionResults:
          get(action, 'meta.shouldUnionResults') ||
          get(action, 'requestOptions.meta.shouldUnionResults') ||
          get(action, 'meta.beginAction.meta.shouldUnionResults'),
      }),
    }
  },

  [types.ENTITIES_FETCH_FAILED]: (state, action) => {
    const info = entitiesFetchInfo(state, action)
    const { pageInfo, isRefetch } = info
    const didChangeQuery = pageInfo.networkStatus === networkStatusEnum.setVariables

    return {
      ...state,
      lastError: action.payload,
      lastStatus: get(action.payload, 'response.status'),
      entitySets: mergeEntitySets({
        state,
        ...info,
        pageInfo: {
          networkStatus: networkStatusEnum.error,
        },
        shouldClearAllPages: isRefetch || didChangeQuery,
        // Failure actions are non-destructive
        shouldUnionResults: true,
      }),
    }
  },
}

entityListReducers[types.ENTITIES_UPDATE_SUCCEEDED] = entityListReducers[types.ENTITIES_CREATE_SUCCEEDED]
