import type { InputFormFieldProps } from '@indeed/ifl-components'
export const FLEX_CORE_API_OVERRIDE_URL = 'FLEX_CORE_API_OVERRIDE_URL'
export const FLEX_OAUTH_DATA_STORAGE_KEY = 'flex.oauthData'

export const AGENCY_URL_PATTERN = new RegExp(/agency(-demo)?\.indeedflex\.(com|co\.uk)/i)
export const MARVIN_AGENCY_URL_PATTERN = new RegExp(
  /flex-agency-(demo-)?(us|uk)\.sandbox(\.qa)?\.indeed\.net/i,
)

export const employerTiers = {
  tier1: 1,
  tier2: 2,
  tier3: 3,
  tier4: 4,
}

export const mediaQueries = {
  downLG: '(max-width:1919.95px)',
  downMDish: '(max-width:1199.95px)',
  downMD: '(max-width:959.95px)',
  downTablet: '(max-width:768px)',
}

const mediaQueryKeys = Object.keys(mediaQueries) as Array<keyof typeof mediaQueries>
export const getMediaQueryDefinition = (key: (typeof mediaQueryKeys)[number]) =>
  `@media screen and ${mediaQueries[key]}`

export const countryCodes = {
  GB: 'GB',
  IE: 'IE',
  US: 'US',
}

export const locales = {
  enUS: 'en-US',
  enGB: 'en-GB',
}

export const countryCurrency = {
  [countryCodes.GB]: { symbol: '£', code: 'GBP' },
  [countryCodes.IE]: { symbol: '€', code: 'EUR' },
  [countryCodes.US]: { symbol: '$', code: 'USD' },
}

const dayNumFullMonthFullYear = 'D MMMM YYYY'
export const dateFormats = {
  dayNumDoubleFullMonth: {
    GB: 'DD MMMM',
    US: 'MMMM DD',
  },
  dayNumDoubleShortMonth: {
    GB: 'DD MMM',
    US: 'MMM DD',
  },
  dayNumDoubleShortMonthTime: {
    GB: 'DD MMM HH:mm',
    US: 'MMM DD HH:mm',
  },
  shortDayNumShortMonthFullYear: {
    GB: 'D MMM YYYY',
    US: 'MMM D YYYY',
  },
  dayNumDoubleShortMonthFullYear: {
    GB: 'DD MMM YYYY',
    US: 'MMM DD YYYY',
  },
  fullDayFullMonthFullYear: {
    GB: 'dddd, D MMMM YYYY',
    US: 'MMMM dddd, D YYYY',
  },
  shortDayShortMonth: {
    GB: 'ddd, D MMM',
    US: 'MMM ddd, D',
  },
  fullDayFullMonth: {
    GB: 'dddd D MMMM',
    US: 'MMMM dddd D',
  },
  shortDayFullMonth: {
    GB: 'ddd D MMMM',
    US: 'MMMM ddd D',
  },
  dayNumShortMonth: {
    GB: 'D MMM',
    US: 'MMM D',
  },
  dayNumShortMonthShortSeparator: {
    GB: 'D/M',
    US: 'M/D',
  },
  monthDayRangeFullFrom: {
    GB: 'D',
    US: 'MMMM D',
  },
  monthDayRangeFullTo: {
    GB: dayNumFullMonthFullYear,
    US: 'D, YYYY',
  },
  dayRangeFullFrom: {
    GB: 'D MMMM',
    US: 'MMMM D',
  },
  dayRangeFullTo: {
    GB: dayNumFullMonthFullYear,
    US: 'MMMM D, YYYY',
  },
  dayNumFullMonth: {
    GB: 'D MMMM',
    US: 'MMMM D',
  },
  dayNumShortMonthFullDay: {
    GB: 'D MMM, dddd',
    US: 'MMM D, dddd',
  },
  dayNumFullMonthFullYear: {
    GB: dayNumFullMonthFullYear,
    US: 'YYYY MMMM D',
  },
  dayNumShortMonthTime: {
    GB: 'D MMM HH:mm',
    US: 'MMM D HH:mm',
  },
  dayNumMonthNumFullYear: {
    GB: 'DD/MM/YYYY',
    US: 'MM/DD/YYYY',
  },
  dayNumMonthNumFullYearShort: {
    GB: 'D/M/YYYY',
    US: 'M/D/YYYY',
  },
  dayOfWeekShortDayOfMonthShortMonthShort: {
    GB: 'EEE, D MMM',
    US: 'EEE, MMM D',
  },
  FULL_ISO_8601: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  DATE_ISO_8601: 'YYYY-MM-DD',
  FULL_ISO_8601_V1: 'YYYY-MM-DDTHH:mm:ssZ:Z',
}

export const brandKeys = {
  SYFT: 'SYFT',
  INDEED_FLEX: 'INDEED_FLEX',
}

const privacyPolicy = 'https://hrtechprivacy.com/brands/indeedflex'
const syftForceGuide = 'https://indeedflex.co.uk/employers/employer-hub'
const platformName = 'Indeed Flex'

export const brandDefaults = {
  [brandKeys.SYFT]: {
    key: brandKeys.SYFT,
    name: platformName,
    platformName,
    internalResourcingPlatformName: 'Indeed Flex+',
    platformWorkersName: 'Flexer',
    url: 'https://indeedflex.co.uk',
    countryCode: countryCodes.GB,
    currency: countryCurrency[countryCodes.GB],
    locale: locales.enGB,
    links: {
      termsAndConditions: 'https://indeedflex.co.uk/client-terms/',
      flexPlusTermsAndConditions: 'https://indeedflex.co.uk/indeedflexplus-terms-and-conditions/',
      privacyPolicy,
      syftForceGuide,
      syftGuide: syftForceGuide,
    },
    supportDetails: {
      name: 'Indeed Flex Support',
      telephoneNumber: '0203 488 1383',
      email: 'clientsupport@indeedflex.com',
    },
  },
  [brandKeys.INDEED_FLEX]: {
    key: brandKeys.INDEED_FLEX,
    name: platformName,
    platformName,
    internalResourcingPlatformName: 'Indeed Flex+',
    platformWorkersName: 'Flexer',
    url: 'https://indeedflex.com',
    countryCode: countryCodes.US,
    currency: countryCurrency[countryCodes.US],
    locale: locales.enUS,
    links: {
      termsAndConditions: 'https://indeedflex.com/client-terms/',
      flexPlusTermsAndConditions: 'https://indeedflex.com/indeed-flex-plus-terms-and-conditions/',
      privacyPolicy,
      syftForceGuide,
      syftGuide: syftForceGuide,
    },
    supportDetails: {
      name: 'Indeed Flex Support',
      telephoneNumber: '+1 (737) 300-9696',
      email: 'clientsuccess@indeedflex.com',
      clientEmail: 'customersuccess@indeedflex.com',
    },
  },
}

export const shiftBookingType = {
  soft: 'soft',
  hard: 'hard',
  offer: 'offer',
}

export const signoutDelay = 1000 * 30
export const rehydrateKeyPrefix = 'syft2webclient'

export const SELECT_ALL_OPTION_ID = 'select_all_option'
export const DEFAULT_ALLOCATED_TIME = 365

// Save re-renders when value does not change
export const SELECT_ALL_OPTION_ID_AS_VALUE = [SELECT_ALL_OPTION_ID]

export const DEBOUNCE_TIME = 250

export const AGENCY_JOBS_STATUSES = {
  pending: 'pending',
  accepted: 'accepted',
  completed: 'completed',
  incomplete: 'incomplete',
}

export const AGENCY_JOBS_STATUS_OPTIONS = {
  [AGENCY_JOBS_STATUSES.pending]: {
    status: AGENCY_JOBS_STATUSES.pending,
    label: 'Pending',
    className: 'pendingStatus',
    action: 'Respond',
    buttonColor: '#BF259A',
  },
  [AGENCY_JOBS_STATUSES.accepted]: {
    status: AGENCY_JOBS_STATUSES.accepted,
    label: 'Accepted',
    className: 'acceptedStatus',
    action: 'Book workers',
    buttonColor: 'none',
  },
  [AGENCY_JOBS_STATUSES.completed]: {
    [AGENCY_JOBS_STATUSES.completed]: AGENCY_JOBS_STATUSES.completed,
    label: 'Completed',
    className: 'completedStatus',
    action: 'View workers',
    buttonColor: undefined,
  },
  [AGENCY_JOBS_STATUSES.incomplete]: {
    [AGENCY_JOBS_STATUSES.incomplete]: AGENCY_JOBS_STATUSES.incomplete,
    label: 'Incomplete',
    className: 'incompleteStatus',
    action: 'Book workers',
    buttonColor: 'none',
  },
}

export const AGENCY_JOB_DATE_FORMAT = {
  weekday: 'short',
  day: '2-digit',
  month: '2-digit',
}

export const AGENCY_JOB_TIME_FORMAT = { format: 'shortHourMinuteAMPM' }

export const LOGIN_TYPES = {
  legacy: 'legacy',
  redirect: 'redirect',
}

export const LOCAL_STORAGE_CREATE_JOB_POST = 'CREATE_JOB_POST'

export const formFieldStyles = {
  display: 'flex',
  mb: 16,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  '& > div:nth-of-type(1)': {
    width: '100%',
  },
} as InputFormFieldProps

export const INTERNAL_STAFF = {
  allStaff: 'allStaff',
  myVenueStaff: 'myVenueStaff',
  individualStaff: 'individualStaff',
}

export const INDEED_FLEXERS = {
  letFlex: 'letFlex',
  myIndeedFlexpool: 'myIndeedFlexpool',
  individualFlexers: 'individualFlexers',
}

export const MY_AGENCIES = {
  myAgencies: 'myAgencies',
  specificAgencies: 'specificAgencies',
}

export const PUBLISH_OFFER_TO = {
  INTERNAL_STAFF: {
    allStaff: 'all',
    myVenueStaff: 'priority:1',
    individualStaff: 'individual',
  },
  INDEED_FLEXERS: {
    letFlex: 'network,random',
    myIndeedFlexpool: 'network',
    individualFlexers: 'individual',
  },
  MY_AGENCIES: {
    agency: 'agency',
  },
}

export const partialMatch = (value: string) => new RegExp(value.replace(/[^a-z0-9 ]/gi, ''), 'i')

export const AUTH_KEYS = {
  impersonatorId: 'impersonatorId',
  accessToken: 'access_token',
  code: 'code',
}
