import { get } from 'lodash'

import { userTypes } from 'api/entities/user'
import * as actionTypes from 'actions/action-types'
import { MANAGE_LOCATION_NETWORK } from 'lib/syft-api-employer-account-policy'
import { getQueryParams, isActivePath, getCurrentPath } from 'lib/history'
import { getInternalResourcingStatus } from 'selectors/platform'
import { hasPermissionWithOrWithoutWorkLocations, hasExplicitPermissionAnywhere } from 'selectors/auth'
import { hasFeature } from 'selectors/config'
import { store } from '../store'

const getReturnToPathOrRedirectPath = () => {
  const { returnTo } = getQueryParams()

  if (returnTo) {
    try {
      const url = new URL(returnTo, window.location.origin)

      return `${url.pathname}${url.search}`
    } catch {}
  }

  return !isActivePath('/signin') ? getCurrentPath() : undefined
}

/**
 * Any user can authenticate by providing an `access_token` GET param in the URL.
 * This is used to allow admins to log in as an employer.
 *
 * https://syftapp.atlassian.net/wiki/display/SV/Log+in+as+employer+User+Admin+API
 *
 */
export const consumeAccessTokenParam = () => {
  const accessToken = getQueryParams().access_token
  const impersonatorId = getQueryParams().impersonatorId

  if (accessToken) {
    store.dispatch({ type: actionTypes.AUTH_LOG_OUT })
    store.dispatch({
      type: actionTypes.AUTH_LOGIN_BEGIN,
      payload: { accessToken, impersonatorId },
      meta: {
        // redirect to intended page
        redirect: getReturnToPathOrRedirectPath(),
      },
    })
  }
}

export const consumeIndeedToken = () => {
  const indeedToken = getQueryParams().code
  if (indeedToken) {
    const storeState = store.getState()
    store.dispatch({ type: actionTypes.AUTH_LOG_OUT })
    store.dispatch({
      type: actionTypes.AUTH_INDEED_LOGIN_BEGIN,
      payload: {
        indeedToken,
        profileType: hasFeature(storeState, 'agencyPortal') ? userTypes.agency : userTypes.employer,
      },
      meta: {
        // redirect to intended page
        redirect: getReturnToPathOrRedirectPath(),
      },
    })
  }
}

export const requireAgency = () => {
  const storeState = store.getState()
  const hasAgencyId = get(storeState, 'auth.userData.agencyId')
  return {
    shouldRedirect: !hasAgencyId,
    path: '/signin',
  }
}

export const requireAgencyAdmin = () => {
  const storeState = store.getState()
  const isAdmin = storeState?.auth?.userData?.agencyAccount?.level === 'admin'
  return {
    shouldRedirect: !isAdmin,
    path: '/jobs',
  }
}

export const requireApprovedEmployer = () => {
  const completeEmployer = requireCompleteEmployer()
  if (completeEmployer.shouldRedirect) return completeEmployer
  const storeState = store.getState()
  const isEmployerApproved = get(storeState, 'auth.userData.employerApproved')
  return {
    shouldRedirect: !isEmployerApproved,
    path: '/waiting-for-approval',
  }
}

// requireEmployerProfile and requireCompleteEmployer are identical
export const requireCompleteEmployer = () => {
  const storeState = store.getState()
  const isEmployerComplete = get(storeState, 'auth.userData.employerComplete')
  return {
    shouldRedirect: !isEmployerComplete,
    path: '/complete-employer-signup',
  }
}

export const requirePermissions = (permissions, workLocationIds) => () => {
  const state = store.getState()
  return {
    shouldRedirect: !hasPermissionWithOrWithoutWorkLocations(state, permissions, workLocationIds),
    path: '/access-denied',
  }
}

export const requireManageNetworkPermission = () => () => {
  const state = store.getState()
  const isInternalResourcing = getInternalResourcingStatus(state)
  // manage_employer_network is used on FLex+ platforms only
  const isAuthorised = isInternalResourcing
    ? hasExplicitPermissionAnywhere(state, [MANAGE_LOCATION_NETWORK])
    : true
  return {
    shouldRedirect: !isAuthorised,
    path: '/access-denied',
  }
}

/**
 *
 * @returns {{path: string, shouldRedirect: boolean}}
 */
export const indexPathRoute = () => {
  const storeState = store.getState()
  return {
    shouldRedirect: true,
    path: !storeState.auth.isLoggedIn
      ? '/signin'
      : hasFeature(storeState, 'agencyPortal')
      ? '/jobs'
      : '/schedule',
  }
}

export const publicOnlyPathRoute = () => {
  const storeState = store.getState()
  return {
    shouldRedirect: storeState.auth.isLoggedIn,
    path: '/',
  }
}
