import { flow, intersection, get, isNil, partialRight, every } from 'lodash'
import { compose, get as getFp, isEmpty, filter, flattenDepth, map } from 'lodash/fp'
import { createSelector } from 'reselect'

import { makeCollectionSelectors } from 'lib/redux-crud/selectors'
import { checkPermission, expandPermissions } from 'lib/syft-api-employer-account-policy'
import { createWorkLocationTree } from 'api/entities/trustedWorkLocation'
import { createTreeMap, filterTree, mapTreeDF } from 'lib/tree'
import { idForEntity } from 'api/schemas'
import { countryCodes, countryCurrency } from 'common/constants'
import { getInternalResourcingStatus, getPlatformBrand } from 'selectors/platform'
import { hasFeature } from 'selectors/config'

const getAuth = state => get(state, 'auth')

export const getEmployerCountryCode = createSelector(
  [state => get(state, 'auth.userData.employer.countryCode'), getPlatformBrand],
  (countryCode, platformBrand) => countryCode || platformBrand.countryCode,
)

export const getIsAdmin = state => get(state, 'auth.isAdmin')

export const getIsInternalEmployer = state => get(state, 'auth.userData.employerInternal')

export const getEmployerCurrency = createSelector(
  [getEmployerCountryCode, getPlatformBrand],
  (countryCode, platformBrand) =>
    countryCurrency[countryCode] || platformBrand.currency || countryCurrency[countryCodes.GB],
)

export const trueCurrentUser = rootState => {
  const userData = getFp('auth.userData', rootState)
  // Auth reducer's initial state is `userData: { email: '' }`.
  // Only authenticated users have an email.
  return isEmpty(get(userData, 'email')) ? null : userData
}

export const currentUser = rootState => {
  const userData = getFp('auth.userData', rootState)
  // We saw an empty object as userData
  return isEmpty(userData) ? null : userData
}

export const currentUserId = rootState => currentUser(rootState)?.id

export const isLoggedIn = rootState => get(rootState, 'auth.isLoggedIn')

export const getEmail = rootState => {
  return get(trueCurrentUser(rootState), 'email') || null
}

export const getAccessToken = state => get(state, 'auth.oauthData.accessToken')

export const getEmployerId = flow(currentUser, getFp('employerId'))

export const isLoggedInAsEmployer = state => !isNil(getEmployerId(state))

export const isAgencyUser = state => getFp('auth.userData.mainProfileType')(state) === 'agency'

export const getAgencyId = state => get(state, 'auth.userData.agencyId')

export const getAccountType = state =>
  hasFeature(state, 'agencyPortal') ? 'agencyAccount' : 'employerAccount'

/**
 * PERMISSIONS
 **/

/**
 * See https://syftapp.atlassian.net/wiki/spaces/SV/pages/88277031/Employer+Account%27s+Permissions
 *
 * We have 3 levels of the permissions.
 * This config will help us to flatify the permissions to simpify the implementation on UI
 * https://syftapp.slack.com/archives/C7KU0TJQZ/p1508415036000145
 * e.g. if user has the permission manage_financial (employer level)
 * it also has the permission 'view_financial' on the location level.
 *
 * According to the wiki, employer with manage_venues permission can always access
 * API endpoints with manage_jobs permission. For sake
 * of simplifying <ShowWithPermissions> usage we will expand this permission here.
 **/

export const makePermissions = (expandConfig = {}) => {
  const expandedPermissions = createSelector(currentUser, getFp('employerAccount.expandedPermissions'))

  return createSelector(
    expandedPermissions,
    map(i => ({
      ...i,
      codes: expandPermissions(i.codes, expandConfig),
    })),
  )
}

export const permissionsByWorkLocationId = (state, workLocationId, expandConfig = {}) =>
  compose(
    partialRight(expandPermissions, expandConfig),
    flattenDepth(2),
    map('codes'),
    filter({ workLocationId: workLocationId || null }),
    makePermissions(expandConfig),
  )(state)

export const getLevel = (currentUser, accountType) => currentUser?.[accountType]?.level

const PERMISSIONS_EXPAND_CONFIG = {
  manage_financial: ['view_financial'],
  manage_venues: ['manage_jobs'],
  manage_employer_network: ['manage_location_network'],
}

// Syft API has workLocation = null for the employer permissions
export const hasPermission = (state, permissions, workLocationId = null, options) =>
  isLoggedIn(state) &&
  checkPermission(
    getLevel(currentUser(state), getAccountType(state)),
    permissions,
    permissionsByWorkLocationId(state, workLocationId, PERMISSIONS_EXPAND_CONFIG),
    options,
  )

const hasPermissionWithWorkLocations = (state, permissions, workLocationIds, options) =>
  workLocationIds.reduce((acc, cur) => acc || hasPermission(state, permissions, cur, options), false)

export const hasPermissionWithOrWithoutWorkLocations = (state, permissions, workLocationIds, options) =>
  (workLocationIds &&
    workLocationIds.length &&
    hasPermissionWithWorkLocations(state, permissions, workLocationIds)) ||
  hasPermission(state, permissions, null, options)

export const makeWorkLocationIdsWithPermissions = (permissions, expandConfig = PERMISSIONS_EXPAND_CONFIG) =>
  createSelector(
    makePermissions(expandConfig),
    compose(
      map('workLocationId'),
      filter(i => !!intersection(i.codes, permissions).length),
    ),
  )

export const isAuthorizedEmployer = state => {
  const userData = get(state, 'auth.userData')
  return !!userData && !!userData.employerApproved && !!userData.employerComplete
}

// Checks whether the employerAccount has a specific permission in any location or globally
// TODO convert to selector generator
export const hasExplicitPermissionAnywhere = createSelector(
  (state, ...args) => makeWorkLocationIdsWithPermissions(...args)(state),
  compose(getFp('employerAccount'), currentUser),
  isAuthorizedEmployer,
  (explicitWorkLocationIdsWithPermissions, employerAccount, isAuthorizedEmployer) =>
    isAuthorizedEmployer &&
    (get(employerAccount, 'level') === 'main' ||
      get(explicitWorkLocationIdsWithPermissions, 'length', 0) > 0),
)

export const getCurrentEmployerAccount = createSelector(currentUser, getFp('employerAccount'))

/**
 * Selects the work locations that an employer account has specific permissions to.
 *
 * Note:
 * The problem we encounter is that BE only returns explicit permissions to locations.
 * For example, when an employer account has permission to an area, it does not also
 * return the venue above it.
 * Here we create a full map-tree of all work locations that the user has partial or full
 * permissions to.
 *
 * @param {Object} state
 * @param {Array[string]} permissions
 *
 * @returns a map of work locations tree
 */
export const makeWorkLocationsWithPermissions = (permissions, expandConfig) => {
  const collectionSelector = makeCollectionSelectors()
  const workLocations = createSelector(
    state => collectionSelector.getEntityList(state.lightVenues),
    createWorkLocationTree,
  )
  const locationsIdWithPermissions = makeWorkLocationIdsWithPermissions(permissions, expandConfig)

  return createSelector(
    workLocations,
    locationsIdWithPermissions,
    getCurrentEmployerAccount,
    (workLocations, explicitWorkLocationIdsWithPermissions, employerAccount) => {
      const permittedLocations = new Set(explicitWorkLocationIdsWithPermissions)
      // hasPermissionToAllLocations :: bool
      const hasPermissionToAllLocations =
        get(employerAccount, 'level') === 'main' || permittedLocations.has(null)

      function buildScopedWorkLocationTree(workLocationsRoot) {
        const hasPermissionToLocation = node =>
          hasPermissionToAllLocations || permittedLocations.has(node.value.id)
        // fullAccess refers to recursively having full permission to all sublocations
        const setFullAccess = node => ({
          ...node,
          fullAccess:
            get(node, 'children.length', 0) ===
              get(workLocations, [node.value.id, 'children', 'length'], 0) &&
            every(map('fullAccess', get(node, 'children'))),
        })

        return compose(mapTreeDF(setFullAccess), filterTree(hasPermissionToLocation))(workLocationsRoot)
      }

      const scopedTree = buildScopedWorkLocationTree(get(workLocations, '$root'))

      return createTreeMap(scopedTree, idForEntity)
    },
  )
}

export const getIsDisabledInternalNetwork = state =>
  get(state, 'auth.userData.platform.disableInternalWorkers')

export const hasInternalNetworkSupport = createSelector(
  [getInternalResourcingStatus, getIsDisabledInternalNetwork],
  (internalResourcingStatus, disabledInternalNetwork) => !disabledInternalNetwork && internalResourcingStatus,
)

export const getLogoutWarning = state => get(state, 'auth.logoutWarning')

export const getResetPasswordToken = createSelector([getAuth], auth => get(auth, 'resetPasswordToken'))

export const getResetEmail = createSelector([getAuth], auth => get(auth, 'resetEmail'))

export const getEmployerPictureUUID = state => get(state, 'auth.userData.employer.picture.uuid')

export const getImpersonatorId = state => get(state, 'auth.oauthData.impersonatorId')
