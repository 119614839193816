/* eslint-disable func-names */
/* eslint-disable import/order */

import { pickBy, get, isInteger, isNil, identity, first, last, omit } from 'lodash'
import pluralize from 'pluralize'
import { call, put, select, getContext } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { entityApiRequest as rawEntityApiRequest } from 'api'
import { deserializeActionType } from 'api/actionTypes'
import { getPageInfo, keyForQuery } from 'lib/redux-crud/selectors'
import { apiActions } from 'api/endpoints'
import { putFailureAction } from 'sagas/errorMessage'

const { allRequestTypesOfActionType } = types

/**
 * @param {Response} response
 * @param {string} headerName
 * @param {boolean||integer} options options.isInteger If true, parses value as integer
 */
const parseHeader = (response, headerName, options = {}) => {
  const headers = response.headers
  const value = headers && headers.get(headerName)
  return options.isInteger ? value && parseInt(value, 10) : value
}

/**
 * Return {url, page, pageQueryParams} based on meta and apiAction
 *
 * NB: Some paginated endpoints may provide a URL for the next page. If we have it
 * let's use it instead of computing the URL by ourselves
 * @param {entityType, apiAction, entity, requestMeta}
 */
function* getRequestedPage({ entityType, apiAction, entity, requestMeta }) {
  let url = null
  let page = null
  let pageQueryParams = null
  const pageFromMeta = get(requestMeta, 'page')
  // TODO shouldCacheAllQueries flag will removed entirely
  const shouldCacheAllQueries = get(requestMeta, 'shouldCacheAllQueries')
  const shouldLoadSpecificPage =
    apiAction === apiActions.list && (isInteger(pageFromMeta) || pageFromMeta === 'next')

  if (shouldLoadSpecificPage) {
    const setKey = keyForQuery(shouldCacheAllQueries ? entity : {})

    const pageInfo = yield select(rootState => {
      const entityState = rootState[pluralize(entityType)]
      return getPageInfo(entityState, { setKey })
    })

    page =
      pageFromMeta === 'next'
        ? // Reducer has already processed FETCH_ENTITIES_BEGIN action
          Object.keys(pageInfo.pages).length
        : pageFromMeta

    url = get(pageInfo.pages[page - 1], 'nextPageUrl')

    if (!url) {
      pageQueryParams = { page }
    }
  }

  return { url, page, pageQueryParams }
}

/**
 * Generate url for pagaination
 * TODO: Refactor
 *
 * @param {*} response
 * @param {*} requestedPage
 */
const getNextPage = (response, requestedPage) => {
  // Syft API's batched queries (with X-Next-Batch-Path) don't have the page number
  // in headers
  const pageHeaderValue = parseHeader(response, 'X-Page', { isInteger: true })
  const page = isInteger(pageHeaderValue) ? pageHeaderValue : requestedPage
  const nextBatchPathHeader = parseHeader(response, 'X-Next-Batch-Path')
  // Renamed to nextPageUrl in case we start using it for standard pagination
  // Strip base path from this URL so we can use it in api/endpoints
  // TODO Refactor
  const nextPageUrl = nextBatchPathHeader && nextBatchPathHeader.replace('/api/v2', '')
  return { page, url: nextPageUrl }
}

/**
 * Construct entityActionRequest() defaults. Some are pulled from saga context
 * to simplify usage.
 *
 * @param {*} options entityApiRequest, normalizePayload ...
 */
function* getDefaultsFromSagaContext(options = {}) {
  const { context: contextOpt } = options
  const entityApiRequestCtx = yield getContext('entityApiRequest')
  const entityApiContextCtx = yield getContext('entityApiContext')
  const context = { ...entityApiContextCtx, ...contextOpt }
  const entityApiRequest = options.entityApiRequest || entityApiRequestCtx || rawEntityApiRequest
  const normalizePayload = options.normalizePayload || identity
  const beforeRequest = options.beforeRequest || context.beforeRequest || identity

  return {
    ...options,
    context: omit(context, ['beforeRequest']),
    normalizePayload,
    entityApiRequest,
    beforeRequest,
  }
}

/**
 * Request options for api/index#entityApiRequest()
 * apiAction, entityType, entity, requestMeta: options.requestMeta, pageQueryParams, context: options.context, url
 */
const entityApiRequestOptions = ({
  apiAction,
  entityType,
  entity,
  requestMeta,
  pageQueryParams,
  context,
  url,
}) => ({
  apiAction,
  entityType,
  // TODO: We're sending pageQueryParams to api/index#entityApiRequest knowing that they
  // will be serialized as query params (this ceases to be an entity)
  entity: { ...entity, ...pageQueryParams },
  meta: pickBy(
    {
      ...requestMeta,
      ...context,
      // If we have a URL for the next page, instruct api/index#entityApiRequest to use it
      url,
    },
    x => !isNil(x),
  ),
})

/**
 * Prepare all the metadata needed by redux-crud reducers
 */
const makeSuccessActionMeta = ({ requestMeta, requestOptions, beginAction, response, page, nextPageUrl }) =>
  pickBy(
    {
      ...requestMeta,
      requestOptions,
      beginAction,
      pageSize: parseHeader(response, 'X-Per-Page', { isInteger: true }),
      totalPages: parseHeader(response, 'X-Total-Pages', { isInteger: true }),
      totalCount: parseHeader(response, 'X-Total', { isInteger: true }),
      updatedAt: parseHeader(response, 'X-Sync-Time'),
      page,
      nextPageUrl,
      shouldClearAllPages: get(beginAction, 'meta.shouldClearAllPages'),
      shouldCacheAllQueries: get(beginAction, 'meta.shouldCacheAllQueries'),
    },
    x => !isNil(x),
  )

/**
 * See docs for entityActionRequest().
 *
 * Unlike entityActionRequest() this method doesn't dispatch success and failure
 * actions thus enabling fine grained control over request processing.
 *
 * Unlike raw entityApiRequest this method is saga-driven and store-aware which enables
 * us to provide some additional functionality. You can almost always use this method
 * instead of raw api/index#entityApiRequest.
 *
 * @param {optionsArg}
 * options.beginAction Entity list reducer uses beginAction to infer a query (entitySet) and meta.shouldCacheAllQueries
 */
export function* entityApiRequest(optionsArg) {
  let requestOptions = null
  try {
    const options = yield call(getDefaultsFromSagaContext, optionsArg)
    const { apiAction, entityType, entity, beforeRequest } = options
    const {
      page: requestedPage,
      url,
      pageQueryParams,
    } = yield call(getRequestedPage, {
      entityType,
      apiAction,
      entity,
      requestMeta: options.requestMeta,
    })
    requestOptions = entityApiRequestOptions({
      apiAction,
      entityType,
      entity,
      requestMeta: options.requestMeta,
      pageQueryParams,
      context: options.context,
      url,
    })

    if (beforeRequest) {
      yield call(beforeRequest, requestOptions)
    }

    const ret = yield call(options.entityApiRequest, requestOptions)
    const { payload: pristinePayload, response, meta: requestMeta } = ret
    const { page, url: nextPageUrl } = getNextPage(response, requestedPage)
    const payload = options.normalizePayload(pristinePayload)
    const meta = makeSuccessActionMeta({
      requestMeta,
      requestOptions,
      response,
      page,
      beginAction: options.beginAction,
      nextPageUrl,
    })
    return { payload, meta }
  } catch (error) {
    error.originalError = error.toString()
    error.requestOptions = requestOptions
    throw error
  }
}

/**
 * Make a standard redux-crud API request:
 *   1. take a standard redux-crud action
 *       e.g. { type: VENUE_SAVE_BEGIN, payload: { id: 1, newProperty: '' } }
 *       or { type: VENUES_FETCH_BEGIN, payload: { searchTerm: 'des' } }
 *   2. call api/index#entityApiRequest() that computes the URL, serializes
 *      params and body, makes the fetch request and returns derserialized body to us
 *   3. dispatch SUCCEEDED and FAILED actions that will be processed by redux-crud
 *      reducers to save the results into Redux store.
 *
 * The view layer (React components) should never need to directly use this method.
 * In sagas/api, this method is called by default for every available redux-crud
 * action type (VENUES_FETCH, VENUES_CREATE,...). Furthermore the view layer should never
 * needs to rely on the returned result of this method, it should only rely on data
 * in Redux store (through redux-crud selectors). In short, the view layer only declares
 * data dependencies (through fetchCollection or fetchEntity), dispatches
 * save / update / delete actions and then reads data from Redux store. There
 * is no request-response mechanism.
 *
 * We partially handle pagination in this method – this entails computing URL
 * for the next page of a query based on Redux state and processing pagination headers
 * from the response.
 *
 * Provide `options.normalizePayload` to customize success action payload (response
 * body) into a format parseable by redux-crud reducers. This usually means converting
 * the response into an array of entities and filling in details like entity identifiers.
 *
 * @param {{ type, payload }} action Standard redux-crud action. Last method argument.
 * @param {Object} options First method argument, optional. Omitting the options is handy
 *   when using the method with takeEvery/Latest effects
 * @param {Function} options.normalizePayload Hook to post-process payload before
 *   dispatching the success action
 * @param {Function} options.beforeRequest Hook called before the request. Blocks the request until completion.
 * @param {Function} options.afterSuccess Hook called after the request succeeds
 *   but before the success action is dispatched. Blocks the success action.
 * @param {Object} options.context Arbitrary context passed to `options.entityApiRequest`
 *   Defaults are taken from saga context.
 * @param {String ~ api/endpoints#apiActions} options.apiAction One of the standard
 *   API actions, by default parsed from `action.type`
 * @param {String} options.entityType Singular entity type, e.g. 'worker' or 'listing'.
 *   See /api/schemas/* for a list of available entity types. By default parsed
 *   from `action.type`
 * @param {String} options.entity Payload for entityApiRequest. If provided, completely
 *   overrides action.payload
 * @param {Function} options.entityApiRequest API call for saving this entity,
 *   api/index#entityApiRequest by default or taken from saga context.
 * @param {Number|'next'} action.meta.page Instruct to fetch a specific page of a query
 *   from the backend. If "next", the next page that's not yet cached will be fetched.
 * @param {Boolean} action.meta.alertOnError Flag to show a UI alert in case the request
 *   fails. Will be removed in future.
 * @param {Number} action.meta.shouldUnionResults Results of a `list` api action will be
 *   unioned with any previous results *for this query*
 * @param {Number} action.meta.shouldCacheAllQueries Instruction for redux-crud reducers.
 *   Should be set to true for most requests.
 * @param {Number} action.meta.shouldClearAllPages Deprecated instruction, now ignored.
 * @return {{ type, payload, meta }} success or failure action
 */
export function* entityActionRequest(...args) {
  const action = last(args)
  const options = args.length === 1 ? {} : first(args)
  const deserializedActionType = deserializeActionType(action.type)
  const allActionTypes = allRequestTypesOfActionType(action.type)

  try {
    const { payload, meta } = yield call(entityApiRequest, {
      ...options,
      entityType: options.entityType || deserializedActionType.entityType,
      apiAction: options.apiAction || deserializedActionType.apiAction,
      entity: action.payload,
      requestMeta: action.meta, // e.g. page: 3, shouldCacheAllQueries: true
      beginAction: action,
    })
    const successAction = { type: allActionTypes.succeeded, payload, meta }
    if (options.afterSuccess) {
      yield call(options.afterSuccess, successAction)
    }
    yield put(successAction)
    return successAction
  } catch (error) {
    const failureAction = {
      type: allActionTypes.failed,
      error,
      beginAction: action,
    }
    if (options.afterFailure) {
      yield call(options.afterFailure, failureAction)
    }
    return yield putFailureAction(failureAction)
  }
}
