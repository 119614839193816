/* eslint-disable func-names */
/* eslint-disable import/order */

import { map, omit, isEmpty } from 'lodash'
import { call, put, all } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { putFailureAction } from './entityCall'

// * Send multiple /bulk_offer requests for every jobId received in action.payload
export function* createBulkOffer(options) {
  yield takeLatestDeep(types.BULK_OFFER_CREATE_BEGIN, function* (action) {
    try {
      const requestOptions = {
        apiAction: apiActions.update,
        entityType: 'bulkOffer',
        meta: options.context,
      }
      const workersForJobs = omit(action.payload, ['$type'])
      yield all(
        map(workersForJobs, (workerIds, jobId) =>
          call(options.entityApiRequest || entityApiRequest, {
            ...requestOptions,
            entity: isEmpty(workerIds) ? { jobId } : { workerIds, jobId },
          }),
        ),
      )
      const meta = { requestOptions }
      yield put({ type: types.BULK_OFFER_CREATE_SUCCEEDED, payload: {}, meta })
    } catch (e) {
      // TODO Error handling needs bulkOffer ID
      return yield putFailureAction({
        type: types.BULK_OFFER_CREATE_FAILED,
        error: e,
        requestOptions: { entity: action.payload },
        beginAction: action,
      })
    }
  })
}
