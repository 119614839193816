import { Redirect, RedirectProps } from 'react-router-dom'
import React, { lazy } from 'react'
import {
  indexPathRoute,
  publicOnlyPathRoute,
  requireAgency,
  requireAgencyAdmin,
  requireApprovedEmployer,
  requireCompleteEmployer,
  requireManageNetworkPermission,
  requirePermissions,
} from './lib/authorization'
import { CREATE_VENUES, MANAGE_VENUES } from './lib/syft-api-employer-account-policy'
import { ProtectedRouteProps } from './lib/router/Route/ProtectedRoute'

import Error404 from './views/errors/Error404'
import Error403 from './views/errors/Error403'
import { workerPlatforms } from './api/entities/worker'
import { RouteProps } from 'lib/router/Route/Route'
import config from 'config'
const TestSettings = lazy(() => import(/* webpackChunkName: "TestSettings" */ 'components/TestSettings'))
const PageFromApi = lazy(() => import(/* webpackChunkName: "PageFromApi" */ 'components/PageFromApi'))

const AgencyJobs = lazy(() => import(/* webpackChunkName: "AgencyJobs" */ 'views/AgencyJobs'))
const AgencyWorkers = lazy(() =>
  import(/* webpackChunkName: "AgencyWorkers" */ 'views/_agency/features/AgencyWorkers').then(module => ({
    default: module.AgencyWorkers,
  })),
)
const AgencyListing = lazy(() => import(/* webpackChunkName: "AgencyListing" */ 'views/AgencyListing'))
const AgencyAccountBranchesPage = lazy(
  () => import(/* webpackChunkName: "AgencyAccountBranchesPage" */ 'views/AgencyAccountBranchesPage'),
)
const Approve = lazy(() => import(/* webpackChunkName: "Approve" */ 'views/Approve'))
const EmployerSignup = lazy(() => import(/* webpackChunkName: "EmployerSignup" */ 'views/EmployerSignup'))
const EmployerAccountSignupPage = lazy(
  () => import(/* webpackChunkName: "EmployerAccountSignupPage" */ 'views/EmployerAccountSignupPage'),
)
const WorkerAccountCompletion = lazy(
  // @ts-expect-error
  () => import(/* webpackChunkName: "WorkerSignup" */ 'views/WorkerAccountCompletion'),
)
const CompleteEmployerSignup = lazy(
  () => import(/* webpackChunkName: "CompleteEmployerSignup" */ 'views/CompleteEmployerSignup'),
)
const AddVenue = lazy(() => import(/* webpackChunkName: "AddVenue" */ 'views/AddVenue'))
const EditListingPage = lazy(
  () => import(/* webpackChunkName: "EditListingPage" */ 'components/EditListingPage'),
)
const InvoicingDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "InvoicingDetails" */ 'components/EditListingPage/InvoicingDetails/InvoicingDetails'
    ),
)
const CardDetailsPage = lazy(
  () => import(/* webpackChunkName: "CardDetailsPage" */ 'components/CardDetailsPage'),
)
const Schedule = lazy(() => import(/* webpackChunkName: "Schedule" */ 'views/_client/features/SchedulePage'))
const ShiftPage = lazy(() => import(/* webpackChunkName: "ShiftPage" */ 'views/_client/features/ShiftPage'))
const ShiftPageRedirect = lazy(
  () => import(/* webpackChunkName: "ShiftPageRedirect" */ 'views/_client/features/ShiftPage/Redirect'),
)
const Staff = lazy(() => import(/* webpackChunkName: "Staff" */ 'views/Staff'))
const StaffByLocation = lazy(() => import(/* webpackChunkName: "StaffByLocation" */ 'views/StaffByLocation'))
const StaffAvailability = lazy(
  () => import(/* webpackChunkName: "StaffAvailability" */ 'views/StaffAvailability'),
)
const Rota = lazy(() => import(/* webpackChunkName: "Rota" */ 'views/Rota'))

const WorkerDetail = lazy(() => import(/* webpackChunkName: "WorkerDetail" */ 'views/WorkerDetail'))
const Venues = lazy(() => import(/* webpackChunkName: "Venues" */ 'views/Venues'))
const EditVenue = lazy(() => import(/* webpackChunkName: "EditVenue" */ 'views/EditVenue'))
const Finance = lazy(
  () =>
    // @ts-expect-error not covered with TS
    import(/* webpackChunkName: "Finance" */ 'views/Finance'),
)
const Inbox = lazy(() => import(/* webpackChunkName: "Inbox" */ 'views/Inbox'))
const BrowseAndOfferPage = lazy(
  () => import(/* webpackChunkName: "BrowseAndOfferPage" */ 'components/BrowseAndOfferPage'),
)
const FinaliseListing = lazy(
  () =>
    import(
      /* webpackChunkName: "FinaliseListing" */ 'components/EditListingPage/FinaliseListing/FinaliseListing'
    ),
)
const CandidatesPage = lazy(
  () => import(/* webpackChunkName: "CandidatesPage" */ 'components/CandidatesPage'),
)
const TimesheetPage = lazy(() => import(/* webpackChunkName: "TimesheetPage" */ 'components/TimesheetPage'))
const AgencyTimesheetPage = lazy(
  () => import(/* webpackChunkName: "AgencyTimesheetPage" */ 'components/AgencyTimesheetPage'),
)
const AgencyAccounts = lazy(() =>
  import(/* webpackChunkName: "AgencyAccountsPage" */ 'components/AgencyAccountsPage').then(module => ({
    default: module.AgencyAccountsPage,
  })),
)

const AgencyTimesheetTab = lazy(
  () => import(/* webpackChunkName: "AgencyTimesheetTab" */ 'components/Agency/Timesheet'),
)

const AgencyAllocationsPage = lazy(
  () => import(/* webpackChunkName: "AgencyAllocationsPage" */ 'components/AgencyAllocationsPage'),
)

const AgencyAccountsInvite = lazy(
  () => import(/* webpackChunkName: "AgencyAccountsInvitePage" */ 'components/AgencyAccountsInvitePage'),
)

const EmployerSingleTimesheetPage = lazy(() =>
  import(/* webpackChunkName: "EmployerSingleTimesheetPage" */ 'views/EmployerSingleTimesheetPage').then(
    module => ({
      default: module.EmployerSingleTimesheetPage,
    }),
  ),
)
const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ 'components/Dashboard').then(file => ({
    default: file.Dashboard,
  })),
)
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ 'views/Settings'))
const SettingsWorkerInvitationsPage = lazy(
  () => import(/* webpackChunkName: "SettingsWorkerInvitationsPage" */ 'views/SettingsWorkerInvitationsPage'),
)
const InviteWorkerPage = lazy(
  () =>
    import(/* webpackChunkName: "InviteWorkerPage" */ 'views/SettingsWorkerInvitationsPage/InviteWorkerPage'),
)
const SettingsUploadPage = lazy(
  () => import(/* webpackChunkName: "SettingsUploadPage" */ 'views/SettingsUploadPage'),
)

const ListingOverviewContainer = lazy(() =>
  import(/* webpackChunkName: "ListingOverviewContainer" */ 'views/ListingOverviewContainer').then(
    module => ({
      default: module.ListingOverviewContainer,
    }),
  ),
)

type RouteConfig = Omit<RouteProps, 'children'> | Omit<ProtectedRouteProps, 'children'> | RedirectProps

export const privilegedRoutes: RouteConfig[] = [
  {
    metadata: { accountablePartyId: 'indeed-flex' },
    exact: true,
    path: '/test-settings',
    component: TestSettings,
  },
]

export const commonRoutes: RouteConfig[] = [
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/',
    authRules: [indexPathRoute],
    component: () => null,
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-platform' },
    path: '/terms-and-conditions',
    exact: true,
    component: () => <PageFromApi id="terms-of-service" />,
  },

  {
    metadata: { accountablePartyId: 'sts-wfm-platform' },
    path: '/privacy-policy',
    exact: true,
    component: () => <PageFromApi id="privacy-policy" />,
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/signup',
    component: EmployerSignup,

    authRules: [publicOnlyPathRoute],
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/signup/:token',
    component: EmployerAccountSignupPage,

    hideNavigation: true,
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/job-seeker-account-completion',
    component: WorkerAccountCompletion,
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-platform' },
    exact: true,
    path: '/community-guidelines',
    component: () => <PageFromApi id="community-guidelines" />,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/complete-employer-signup',
    component: CompleteEmployerSignup,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/listing-overview/:listingId',
    authRules: [requireApprovedEmployer],
    component: ListingOverviewContainer,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/venues/add',
    component: AddVenue,
    authRules: [requireApprovedEmployer, requirePermissions([CREATE_VENUES])],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/post-job',
    authRules: [requireApprovedEmployer],
    component: EditListingPage,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/create-template',
    authRules: [requireApprovedEmployer],
    component:
      // @ts-expect-error not covered by TS
      props => <EditListingPage isTemplate {...props} />,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/card-details',
    authRules: [requireCompleteEmployer],
    component: CardDetailsPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: '/schedule',
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    component: Schedule,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/staff',
    authRules: [requireApprovedEmployer, requireManageNetworkPermission()],
    component: Staff,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/syft-pool-staff',
    authRules: [requireApprovedEmployer, requireManageNetworkPermission()],
    component:
      // @ts-expect-error not covered by TS
      props => <Staff workerPlatform={workerPlatforms.syft} {...props} />,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/staff/location/:workLocationId',
    authRules: [requireApprovedEmployer, requireManageNetworkPermission()],
    component: StaffByLocation,
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/staff-availability',
    component: StaffAvailability,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/rota',
    component: Rota,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/approve',
    component: Approve,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/worker/:id',
    component: WorkerDetail,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/venues',
    component: Venues,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/venues/:venueId/edit',
    component: EditVenue,
    authRules: [requireApprovedEmployer, requirePermissions([MANAGE_VENUES])],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/reports',
    component: Finance,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: ['/inbox', '/inbox/thread/:threadId'],
    component: Inbox,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/agencies',
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/offer/:listingId',
    component: BrowseAndOfferPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/post-on-flex/:listingId',
    component: FinaliseListing,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/day-schedule/:date',
    component: ShiftPageRedirect,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/day-schedule/:listingId/shifts/:shiftId',
    component: ShiftPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/day-schedule/:listingId/shifts/first',
    component: ShiftPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/listings/:listingId/edit',
    component: EditListingPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-time' },
    exact: true,
    path: '/listings/:listingId/review-invoicing',
    component: InvoicingDetails,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/listings/:listingId/candidates',
    component: CandidatesPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-pay' },
    exact: true,
    path: '/listings/:listingId/timesheet',
    component: TimesheetPage,
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-pay' },
    authRules: [requireApprovedEmployer],
    component: EmployerSingleTimesheetPage,
    exact: true,
    path: '/timesheets',
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/settings/worker-invitations',
    component: () => (
      <Settings>
        <SettingsWorkerInvitationsPage />
      </Settings>
    ),
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/settings/worker-invitations/add',
    component: () => (
      <Settings>
        <InviteWorkerPage />
      </Settings>
    ),
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-pay' },
    exact: true,
    path: '/settings/upload-timesheet',
    component: () => (
      <Settings>
        <SettingsUploadPage />
      </Settings>
    ),
    authRules: [requireApprovedEmployer],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/dashboard',
    component: Dashboard,
    authRules: [requireApprovedEmployer],
  },
  {
    exact: true,
    from: '/create-job',
    to: '/post-job',
  },
  {
    exact: true,
    from: '/timesheet/:listingId',
    to: '/listings/:listingId/timesheet',
  },
  {
    exact: true,
    from: '/listings/:listingId',
    to: '/day-schedule/:listingId/shifts/first',
  },
  {
    exact: true,
    from: '/employers/:employerId/listings/:listingId/jobs/:jobId',
    to: '/day-schedule/:listingId/shifts/first',
  },
  {
    exact: true,
    from: '/employers/:employerId/listings/:listingId/jobs/:jobId/candidates/booked',
    to: '/day-schedule/:listingId/shifts/first',
  },
  {
    exact: true,
    from: '/employers/:employerId/listings/:listingId/jobs/:jobId/candidates/applied',
    to: '/day-schedule/:listingId/shifts/first',
  },
  {
    exact: true,
    from: '/employers/:employerId/conversations/:conversationId',
    to: '/inbox/thread/:conversationId',
  },
  {
    exact: true,
    from: '/workers/:workerId/jobs/:jobId',
    to: '/jobs/:jobId',
  },
  {
    exact: true,
    from: '/workers/:workerId/jobs/:jobId/status/:status',
    to: '/jobs/:jobId?status=:status',
  },
  {
    exact: true,
    from: '/workers/:workerId/jobs/:jobId/shifts/:shiftId',
    to: '/jobs/:jobId/shifts/:shiftId',
  },
  {
    exact: true,
    from: '/workers/:workerId/conversations/:conversationId',
    to: '/inbox/thread/:conversationId',
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    path: '/access-denied',
    component: Error403,
  },
  {
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    component: Error404,
  },
]

export const agencyOnlyRoutes: RouteConfig[] = [
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/allocations/:listingUuid/platforms/:platformId',
    component: AgencyAllocationsPage,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/platforms/:platformId/job-details/:jobId',
    component: AgencyListing,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: ['/jobs', '/jobs/:listingUuid'],
    component: AgencyJobs,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/workers',
    component: AgencyWorkers,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/agents',
    component: AgencyAccounts,
    authRules: [requireAgency, requireAgencyAdmin],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/branches',
    component: AgencyAccountBranchesPage,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/agents/invite',
    component: AgencyAccountsInvite,
    authRules: [requireAgency, requireAgencyAdmin],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-pay' },
    exact: true,
    feature: 'agencyPortal',
    path: '/listings/:listingUuid/platforms/:platformId/timesheet',
    component: AgencyTimesheetPage,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-pay' },
    exact: true,
    feature: 'agencyPortal',
    path: '/timesheets',
    component: AgencyTimesheetTab,
    authRules: [requireAgency],
  },
  {
    isPrivate: true,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
    exact: true,
    feature: 'agencyPortal',
    path: '/listings/:listingUuid/platforms/:platformId',
    component: () => <Redirect to="/jobs" />,
    authRules: [requireAgency],
  },
]

const createRoutesConfig = (isAgency: boolean): RouteConfig[] => {
  const routesConfig = isAgency ? agencyOnlyRoutes.concat(commonRoutes) : commonRoutes

  if (process.env.NODE_ENV !== 'production' || config.IS_STAGING_ENV) {
    return privilegedRoutes.concat(routesConfig)
  }

  return routesConfig
}

export default createRoutesConfig
