import areaSchema from './area'
import addressSchema from './address'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    name: {
      type: 'string',
    },
    client_id: {
      type: 'string',
      description: 'Custom identifier (optional)',
    },
    desc: {
      type: 'string',
      description: 'Description',
    },
    arrival_minutes: {
      type: 'integer',
    },
    city_living_wage: {
      type: 'boolean',
      description: 'London Living Wage',
    },
    location: {
      type: 'object',
      properties: {
        address: addressSchema,
      },
    },
    access_instructions: {
      type: 'null',
    },
    areas: {
      type: 'array',
      items: areaSchema,
    },
    address: addressSchema,
  },
  required: [
    'id',
    'name',
    'desc',
    'arrival_minutes',
    'address.line_1',
    'address.city',
    'address.post_code',
    'access_instructions',
    'areas',
  ],
}
